import * as React from 'react';
import {assoc, pipe,} from 'ramda';
import {makeStyles} from '@material-ui/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import theme from '../../themes/lightTheme';
import BasePortableText from '@sanity/block-content-to-react';

const useStyles = makeStyles((theme) => ({
  price: {
      "&::before": {
          content: '"$"'
      },
  },
  unit: {
      "&::before": {
          content: `"Per "`
      }
  }
}))

const serializers = {
    types: {
        block: ({node}) => <div>{node.children.map(t => <Typography color="textSecondary" variant='caption'>{t.text}</Typography>)}</div>
    }
}

function ProductTable ({
  node
}: {
  node: any;
}) {
  const classes = useStyles();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [calculator, setCalculator] = React.useState({
      length: "",
      width: "",
      depth: ""
  })
  const onCalculatorChange = (e, type: 'length' | 'width' | 'depth') => {
    console.log(e.target.value);
    setCalculator(assoc(type, e.target.value, calculator))
  };
  const totalYards = (parseInt(calculator.length) && parseInt(calculator.width) && parseInt(calculator.depth)) ?
  pipe(
      (t) => parseInt(calculator.length) * parseInt(calculator.width),
      (t) => (t * parseInt(calculator.depth)),
      (t) => (t / 12),
      (t) => (t / 27),
      (t) => t < 1 ? Math.ceil(t) : Math.round(t)
      )(0): '––';
  const DesktopHeader = () => (
    <TableRow>
        <TableCell align="left">Product</TableCell>
        <TableCell align="left">Description</TableCell>
        <TableCell align="right">Price</TableCell>
        <TableCell  align="left">Unit</TableCell>
    </TableRow>
  );
  const MobileHeader = () => (
    <TableRow>
        <TableCell align="left">Product</TableCell>
        <TableCell align="right">Price</TableCell>
        <TableCell />
    </TableRow>
  );
  const DesktopRow = ({product}) =>
    <TableRow>
        <TableCell align="left" component="th" scope="row">
        {product.title}
        </TableCell>
        <TableCell align="left" component="th" scope="row">
            <BasePortableText blocks={product.description} serializers={serializers} />
        </TableCell>
        <TableCell align="right" className={classes.price}>
            {product.price}
        </TableCell>
        <TableCell align="left">
            <Typography variant="caption" color="textSecondary" className={classes.unit}>
                {product.unit}
            </Typography>
        </TableCell>
    </TableRow>;
  return (
      <MuiThemeProvider theme={theme}>
          <Container maxWidth="lg">
            <TableContainer
                component={Paper}
                elevation={1}
                style={{
                    padding: theme.spacing(2),
                    borderRadius: 0,
                    marginBottom: theme.spacing(2)
                    // border: `2px solid ${theme.palette.text.primary}`
                }}>
                <Table style={{ minWidth: 650 }} aria-label="simple table" size="small">
                <TableHead>
                    {isSmall && <MobileHeader />}
                    {!isSmall && <DesktopHeader />}
                </TableHead>
                <TableBody>
                    {node.products.map((product) => {
                        return (
                            <DesktopRow product={product} />
                        )
                    })}
                </TableBody>
                </Table>
                <Typography variant='h6' style={{fontWeight: '900', marginBottom: theme.spacing()}}>Calculate how much you need:</Typography>
                <form style={{display: isSmall ? 'grid' : 'flex', gap: theme.spacing(2)}}>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                        <TextField
                            onChange={(e) => onCalculatorChange(e, 'length')}
                            value={calculator.length}
                            type='number'
                            style={{flexGrow: 1}}
                            id="standard-basic"
                            label="Length (feet)"
                            variant='outlined' />
                        {/* <Typography component="span" style={{flexShrink: 1, marginLeft: theme.spacing(2)}}>Feet</Typography> */}
                    </span>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                        <TextField
                            onChange={(e) => onCalculatorChange(e, 'width')}
                            value={calculator.width}
                            type='number'
                            style={{flexGrow: 1}}
                            id="standard-basic"
                            label="Width (feet)"
                            variant='outlined' />
                        {/* <Typography component="span" style={{flexShrink: 1, marginLeft: theme.spacing(2)}}>Feet</Typography> */}
                    </span>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                        <TextField
                            onChange={(e) => onCalculatorChange(e, 'depth')}
                            value={calculator.depth}
                            type='number'
                            style={{flexGrow: 1}}
                            id="standard-basic"
                            label="Depth (inches)"
                            variant='outlined' />
                        {/* <Typography component="span" style={{flexShrink: 1, marginLeft: theme.spacing(2)}}>Inches</Typography> */}
                    </span>
                    <span style={{border: '2px solid red', paddingLeft: theme.spacing(), paddingRight: theme.spacing(), display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography component='span' variant='h4'>{totalYards}</Typography>
                        <Typography
                            style={{marginLeft: theme.spacing()}} component='span' color="textSecondary" variant='caption'>cubic yards</Typography>
                    </span>
                </form>
                <Typography><em>Notes:</em></Typography>
                <Typography>* Sales Tax included in all prices</Typography>
                <Typography>* Delivery fee not included</Typography>
            </TableContainer>
        </Container>
      </MuiThemeProvider>
      
    
  )
}

export default ProductTable;
