import React from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

import PortableText from './portableText';
import Figure from './Figure';
import theme from '../../themes/baseTheme';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100vw',
    maxWidth: 1100,
    // marginBottom: theme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
    // padding: theme.spacing(),
    // paddingTop: theme.spacing(2),
    // [theme.breakpoints.down('md')]: {
    //   padding: 0
    // }
  },
  section: {
    // maxWidth: 900
  },
  body: {
    [theme.breakpoints.down('md')]: {
      // width: '100vw'
    }
  }
}))

export default ({node}) => {
  const classes = useStyles();
  return (
    <div square elevation={0} className={classes.root}>
      {node.inlineContentAlignment === 'Right of image' && <Figure node={node.mainImage} />}
      <PortableText blocks={node.body} />
      {node.inlineContentAlignment === 'Left of image' && <Figure node={node.mainImage} />}

    </div>
  )
}
