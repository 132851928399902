import React from 'react'
import Figure from './Figure';
import Youtube from './youtube';
import BodySection from './bodySection';
import InlineImage from './inlineImage';
import ProductTable from './productTable';

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    youtube: Youtube,
    bodySection: BodySection,
    inlineImage: InlineImage,
    productTable: ProductTable
  }
}

export default serializers;
