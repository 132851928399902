import React from 'react';
import { makeStyles } from '@material-ui/styles';
import sanityConfig from '../../utils/sanity/client-config';
import BasePortableText from '@sanity/block-content-to-react';
import serializers from './serializers';
import './portableTextStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    width: '100vw',
    maxWidth: '100%'
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxWidth: 'calc(100vw - 24px)',
    margin: 'auto'
  },
  content: {
    maxWidth: 1100,
    margin: 'auto'
  }
}))


const PortableText = ({blocks}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <BasePortableText className={classes.container} blocks={blocks} serializers={serializers} {...sanityConfig} />
      </div>
    </div>
  )
};

export default PortableText;
