import React from 'react'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import {getFluidGatsbyImage} from 'gatsby-source-sanity';
import { Textfit } from 'react-textfit';

import sanityConfig from '../../utils/sanity/client-config';
import theme from '../../themes/baseTheme';

const useStyles = makeStyles((theme) => ({
  img: {
    // maxWidth: 600,
    // minWidth: 350,
    // maxHeight: 400,
    textAlign: 'center',
    margin: theme.spacing(),
    marginBottom: theme.spacing(),
    flexGrow: 1,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
    }
    // overflow: 'hidden'
  },
  imgCaption: {
    fontSize: '0.8rem',
    fontStyle: 'italic'
  },
  imgCaptionDefault: {
    fontSize: '1rem',
    background: theme.palette.background.paper,
    color: 'white',
    fontWeight: 'bold',
    padding: theme.spacing()
  },
  imgCaptionPrimary: {
    fontSize: '1rem',
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    padding: theme.spacing()
  },
  imgCaptionSecondary: {
    fontSize: '1rem',
    background: 'white',
    color: 'black',
    fontWeight: 'bold',
    padding: theme.spacing()
  },
  centered: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}))

export default ({node}) => {
  const classes = useStyles();
  const screenIsSmall = useMediaQuery(theme.breakpoints.down('sm'));
  if (!node || !node.asset || (!node.asset._id && !node.asset._ref)) { return null };
  const getMaxWidth = (imgWidth) => {
    switch (true) {
      case (screenIsSmall && imgWidth !== 'Small'): return 300;
      case (imgWidth === 'Full Width'): return 1100;
      case (imgWidth === 'Large'): return 500;
      case (imgWidth === 'Medium'): return 350;
      case (imgWidth === 'Small'): return 200;
      default: return 500;
    }
  }
  const getCaptionClass = (captionType) => {
    switch (captionType) {
      case 'Primary': return classes.imgCaptionPrimary;
      case 'Secondary': return classes.imgCaptionSecondary;
      case 'Default': return classes.imgCaptionDefault;
      default: return classes.imgCaption
    }
  };
  const width = getMaxWidth(node.width);
  const centered = node.centered;
  const fluidProps = getFluidGatsbyImage(
    node.asset._id || node.asset._ref,
    { maxWidth: width },
    sanityConfig
  );
  return (
    <div className={centered ? classes.centered : ''}>
      <figure className={classes.img}  style={{
        maxWidth: width,
        minWidth: width,
        marginLeft: node.width === 'small' ? theme.spacing(6) : theme.spacing(),
        marginRight: node.width === 'small' ? theme.spacing(6) : theme.spacing(),
      }}>
        <Img style={node.height === 'Original Height' ? {width} : {width, height: width}} fluid={fluidProps} alt={node.alt} />
        <figcaption className={getCaptionClass(node.captionVariant)}><Textfit mode='single' max={18}>{node.caption}</Textfit></figcaption>
      </figure>
    </div>
  )
}
