import * as React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Image from 'gatsby-image';
import {
  Dialog, IconButton
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {
  PlayArrowRounded as Play,
  Close
} from '@material-ui/icons';
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: 'transparent'
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    // width: 400,
    width: '100vw',
    // maxHeight: 250,
    overflow: 'hidden'
  },
  video: {
    marginBottom: 0,
    width: 800,
    maxWidth: '100vw',
    minHeight: 350
  }
}))

function HeroVideo ({
  node
}: {
  node: {url: string};
}) {
  const classes = useStyles();
  const id = getYouTubeId(node.url);
  return (
      <div className={classes.videoContainer}>
        {/*<Image fluid={video.mainImage.asset.fluid} />*/}
          <YouTube className={classes.video} opts={{
            // height: 250,
            // width: '100%',
            height: '100%'
          }} videoId={id} />
        
      </div>
  )
}

export default HeroVideo;
