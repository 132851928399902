import React from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Paper } from '@material-ui/core';

import PortableText from './portableText';
import theme from '../../themes/baseTheme';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100vw',
    marginBottom: theme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(),
    paddingTop: theme.spacing(2),
    background: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
}))

export default ({node}) => {
  const classes = useStyles();
  return (
    <Paper square elevation={0} className={classes.root}>
      <PortableText blocks={node.body} />
    </Paper>
  )
}
